// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log("Vite ⚡️ Rails");

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
import Rails from "@rails/ujs";
// import * as Turbo from "@hotwired/turbo";
// import * as Turbo from "@hotwired/turbo-rails";
// import "@hotwired/turbo-rails";
Rails.start();
// Turbo.start();
// require("channels");

// import "@fortawesome/fontawesome-free/css/all";
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
const channels = import.meta.globEager("./**/*_channel.js");

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
import "./application.scss";

const reloadIfIdle = () => {
  const idleStatusElement = document.querySelector("[data-status=idle]");
  const currentUrl = window.location.href;
  if (idleStatusElement) {
    fetch(currentUrl)
      .then((response) => {
        if (response.ok) {
          console.log("will reload", response.ok);
          window.location.reload();
        } else {
          console.log("that url does not reply 200 OK", { currentUrl, response });
        }
      })
      .catch((err) => {
        console.log("fetch failed with:", err);
      });
  }
};
const interval = setInterval(reloadIfIdle, 5 * 60 * 1000);

document.addEventListener("turbo:load", () => {
  console.log("turbo:load");
});
